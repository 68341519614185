<template>
  <div class="codeAuditAlls">
    <div class="codeAuditFirst">
      <div class="codeAuditFirst1">
        <div class="codeAuditFirstImgs">
          <img
            src="https://www.boundaryx.com/wp-content/uploads/2022/05/audit.png"
            alt=""
          />
        </div>
        <div class="codeAuditFirst1">
          <h1>代码审计</h1>
          <p class="codeAuditFirst1-1">
            通过自动化分析工具和人工审查的组合审计方式，检查、分析源代码，充分挖掘源代码中存在的安全缺陷和规范性缺陷问题，并找到这些问题所引发的安全漏洞，提供修订措施和建议，帮助客户从源代码层面发现业务风险。
          </p>
        </div>
      </div>
    </div>
    <div class="codeAuditSecond">
      <h1>服务方式</h1>
      <ul>
        <li>
          <div class="codeAuditSecondImgs">
            <img
              src="https://www.boundaryx.com/wp-content/uploads/2022/05/3-1.webp"
              alt=""
            />
          </div>
          <div class="codeAuditSecond1">
            <p class="codeAuditSecond1-1">半自动扫描</p>
            <p class="codeAuditSecond1-2">
              利用专业的半自动化代码扫描工具对源码进行扫描，检查源码中是否存在已知漏洞。
            </p>
          </div>
        </li>
        <li>
          <div class="codeAuditSecondImgs">
            <img
              src="https://www.boundaryx.com/wp-content/uploads/2022/05/8.webp"
              alt=""
            />
          </div>
          <div class="codeAuditSecond1">
            <p class="codeAuditSecond1-1">人工审计</p>
            <p class="codeAuditSecond1-2">
              阅读代码理解业务逻辑,验证和发现通用的安全漏洞、业务逻辑漏洞以及配置缺陷。
            </p>
          </div>
        </li>
        <li>
          <div class="codeAuditSecondImgs">
            <img
              src="https://www.boundaryx.com/wp-content/uploads/2022/05/9.webp"
              alt=""
            />
          </div>
          <div class="codeAuditSecond1">
            <p class="codeAuditSecond1-1">模拟执行</p>
            <p class="codeAuditSecond1-2">
              在测试环境中模拟执行代码或利用编译器编译部分代码执行，跟踪调试，快速定位问题。
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="codeAuditThird">
      <h1>服务价值</h1>
      <div class="codeAuditThird1">
        <ul>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/11-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">极大减少黑客攻击面</p>
              <span class="codeAuditThird1-2-2">
                全面挖掘源码、业务逻辑漏洞，减少系统中存在的隐藏漏洞，降低漏洞或隐患带来的安全风险，从而极大减少黑客攻击面。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/22-2.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">总结编码习惯</p>
              <span class="codeAuditThird1-2-2">
                总结程序开发人员的编码习惯，挖掘研发的安全盲区，进行定向培训，从根本上解决由于安全基础参差不齐导致的代码隐患。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/33-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">完善企业安全防御体系</p>
              <span class="codeAuditThird1-2-2">
                除了提供审计和报告之外，还提供修复建议，降低系统的安全风险，真正提升企业信息系统的安全系数。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/44.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">降低项目返工成本</p>
              <span class="codeAuditThird1-2-2">
                通过早期代码安全测试可以捕获大多数漏洞并及时被消除，减少因潜在风险导致的意外延迟，也避免在业务上造成重大损失。
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@media only screen and(max-width: 980px) {
  .codeAuditAlls {
    width: 100%;
    .codeAuditFirst {
      margin-top: 80px;
      .codeAuditFirst1 {
        position: relative;
        .codeAuditFirstImgs {
          padding-bottom: 55%;
          background-image: -webkit-linear-gradient(0deg, #eaf5fe, #dde6fd);
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .codeAuditFirst1 {
          width: 60%;
          position: absolute;
          top: 50%;
          left: 3%;
          padding: 30px 0px;
          transform: translate(0, -50%);
          h1 {
            font-size: 16px;
            margin-bottom: 20px;
          }
          .codeAuditFirst1-1 {
            margin-top: 10px;
            display: block;
            opacity: 0.8;
            font-size: 14px;
            color: #51565d;
            line-height: 20px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .codeAuditAlls {
    width: 100%;
    .codeAuditSecond {
      width: 100%;
      margin-top: 40px;
      padding: 20px 40px;
      h1 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 30px;
        text-align: center;
      }
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 80px;
        li {
          border: 1px solid #eae9f2;
          border-radius: 4px;
          overflow: hidden;
          margin-top: 20px;
          .codeAuditSecondImgs {
            position: relative;
            padding-bottom: 53%;
            overflow: hidden;
            transition: all 0.5s;
            img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .codeAuditSecondImgs:hover {
            transform: scale(1.1);
            transition: all 0.5s;
          }
          .codeAuditSecond1 {
            padding: 30px;
            .codeAuditSecond1-1 {
              font-size: 24px;
              color: #1f2329;
              font-weight: 500;
              transition: all 0.5s;
            }
            .codeAuditSecond1-2 {
              font-size: 18px;
              color: #51565d;
              line-height: 30px;
              font-weight: 500;
              margin-top: 16px;
              transition: all 0.5s;
            }
          }
        }
        li:hover {
          background-color: #4c84ff;
          transition: all 0.5s;
        }
      }
    }
     .codeAuditThird {
      width: 100%;
      margin-top: 40px;
      background: #f1f5ff;
      padding: 20px 40px;
      h1 {
        text-align: center;
      }
      .codeAuditThird11 {
        width: 220px;
        text-align: center;
        margin-top: 20px;
        font-size: 20px;
      }
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        li {
          display: flex;
          padding: 20px;
          background-image: linear-gradient(180deg, #f4f7ff, #fcfcfe);
          border-radius: 12px;
          margin-top: 20px;
          .codeAuditThird1-1 {
            img {
              width: 48px;
              height: 48px;
              margin-right: 20px;
            }
          }
          .codeAuditThird1-2 {
            .codeAuditThird1-2-1 {
              font-size: 24px;
              color: #1f2329;
            }
            .codeAuditThird1-2-2 {
              font-size: 16px;
              color: #51565d;
              margin-top: 20px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
}
</style>